import React, { useState } from "react";
import ContactHero from "../images/Heros/Contact-Hero.jpg";
import { Container, Row, Col, Button, Modal } from "reactstrap";
import {
	Layout,
	MediaIconLink,
	ModalBuddy,
	NitehawkMap,
	Seo,
} from "../components";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";

export default function ContactPage() {
	const bodyText = [
		<p>Get in touch or visit us at the field</p>,
		<p>Be sure to stay connected via our Facebook Page</p>,
	];

	const pageData = {
		heroImg: ContactHero,
		page: "contact",
		headerText: "Contact Us",
		bodyText,
		btnText: "",
		btnRoute: "",
		contents: "",
		defaultSport: "",
	};

	const [open, setOpen] = useState(false);

	const toggleModal = () => {
		setOpen(!open);
	};

	return (
		<>
			<Layout
				heroImg={pageData.heroImg}
				page={pageData.page}
				headerText={pageData.headerText}
				bodyText={pageData.bodyText}
				btnText={pageData.btnText}
				btnRoute={pageData.btnRoute}
				contentsDown={pageData.contents}
				defaultSport={pageData.defaultSport}>
				<Seo title='Contact Us' description='NiteHawk contact page' />
				<Container className='my-5'>
					<Row>
						<Col lg='6' className='text-center'>
							<Row className='my-5'>
								<h2>Contact Info</h2>
								<p className='my-3'>(509) 919-4569</p>
								<a
									className='btn btn-light mx-auto my-3'
									style={{ maxWidth: "260px" }}
									href='mailto:dale@nitehawkpaintball.com?subject=Question...&body=I would like to ask...'
									target='_blank'
									rel='noreferrer'>
									dale@nitehawkpaintball.com
								</a>
							</Row>
							<Button size='lg' onClick={toggleModal}>
								Contact Form
							</Button>
							<Row className='my-5'>
								<h2>Our Location</h2>
								<p className='my-1'>39200 Spring Creek Road</p>
								<p className='my-1'>Ford, Washington</p>
								<p className='mt-1'>99013</p>
							</Row>
							<Row className='my-5'>
								<h2>Stay Connected</h2>
								<MediaIconLink route='https://www.facebook.com/groups/280908942374845'>
									{/* The styles for these are in the globalStyles.js file */}
									<FaFacebookSquare className='social-icon' />
								</MediaIconLink>
							</Row>
						</Col>
						<Col lg='6' className='text-center my-auto'>
							{typeof window !== "undefined" && (
								<NitehawkMap
									position={[47.816173, -117.886488]}
									zoom={10}
									markerText={
										<p className='text-center'>
											39200 Spring Creek Road <br /> Ford, Washington <br />
											99013
										</p>
									}
								/>
							)}
						</Col>
					</Row>
				</Container>
			</Layout>
			<Modal
				isOpen={open}
				toggle={toggleModal}
				centered
				className='text-center'
				size='xl'>
				<ModalBuddy contents='form' setContents='' toggleModal={toggleModal} />
			</Modal>
		</>
	);
}
